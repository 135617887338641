<template>
  <div class="flex flex-col items-center justify-center h-full space-y-2.5 my-auto">
    <div class="flex space-x-10">
      <img
        src="/images/logo-clear.png"
        class="w-40 drop-shadow-2xl"
      >

      <h1 class="text-3xl font-semibold hidden sm:block break-words w-min my-auto">
        Construction Commerical Management
      </h1>
    </div>

    <div
      v-if="!loggingIn"
      class="p-4 w-full sm:w-1/2
        sm:mx-auto sm:border sm:shadow-lg sm:rounded-md sm:mt-2 lg:w-1/4 bg-white"
    >
      <form
        class="flex flex-col space-y-2 "
        @submit.prevent="submitForm"
      >
        <label>
          Email
          <AppInput
            v-model="loginForm.form.email"
            name="email"
            type="text"
            :error="loginForm.getErrors('email')"
            :disabled="loggingIn"
            data-test="email"
            required
          />
        </label>

        <label>
          Password
          <AppInput
            v-model="loginForm.form.password"
            name="password"
            type="password"
            :error="loginForm.getErrors('password')"
            :disabled="loggingIn"
            data-test="password"
            required
          />
        </label>


        <div
          class="flex w-full space-x-2"
        >
          <button
            type="submit"
            class="w-1/2 flex items-center justify-center bg-blue-300 py-2 rounded-lg active:bg-blue-500"
            data-test="login"
          >
            Sign In
          </button>

          <RouterLink
            :to="{ name: 'ResetPassword'}"
            class="w-1/2 flex items-center justify-center bg-blue-300 py-2 rounded-lg active:bg-blue-500"
          >
            Forgot Password
          </RouterLink>
        </div>
      </form>
    </div>

    <div v-else>
      <AppSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import useScreenSize, { ScreenSize } from '@/composables/useScreenSize';
import { useNavStore } from '@/store/nav';
import { useProjectsStore } from '@/store/projects';
import { useUserStore } from '@/store/user';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const loggingIn = ref(false);
const userStore = useUserStore();
const projectsStore = useProjectsStore();

const loginForm = useFormData({
  email: '',
  password: '',
});

const { screenSize } = useScreenSize();

const submitForm = async () => {
  loggingIn.value = true;
  loginForm.resetErrors();

  userStore.signIn(loginForm.form.email, loginForm.form.password).then(async () => {
    const [project] = await projectsStore.fetchProjects();

    if(!project) {
      router.push('/');

      return;
    }

    projectsStore.setProject(project.id, false);

    if(screenSize.value < ScreenSize.MD) {
      router.push('/mobile/projects');
    } else if(userStore.isAdmin) {
      window.location.href = '/admin';
    } else {
      if(projectsStore.models.all().length > 1) {
        router.push(`/home/${projectsStore.models.all()[0].id}`);
      } else {
        if(project) {
          router.push(`/home/${project.id}`);
        }
      }
    }
  }).catch((error) => {
    loggingIn.value = false;
    loginForm.setErrors(error.data);
  });
};

const navStore = useNavStore();

navStore.backRoute = undefined;
</script>

<style scoped></style>
