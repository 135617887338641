<template>
  <tr class="even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100 p-2 m-2">
    <td
      class="py-2 text-left pl-3 w-80"
    >
      {{ tradeItem.name }}
    </td>

    <td class="pr-3">
      {{ australianCurrency(tradeItem.budget) }}
    </td>

    <td
      v-if="tradeItemCost?.current_certified && !typingCertified && canEditCertified"
      :class="{ 'cursor-pointer': canEditCertified }"
      class="bg-green-200/50"
      @click="typeCertified"
    >
      <div class="group flex items-center justify-end">
        <Icon
          name="trash"
          class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
          @click.stop.capture="deleteCost"
        />

        <Icon
          v-if="canEditCertified"
          name="edit"
          class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
        />
        {{ tradeItemCost?.current_certified ? australianCurrency(tradeItemCost.current_certified) : '' }}
      </div>
    </td>

    <td
      v-else-if="canEditCertified"
      class="bg-green-200/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="certifiedInput"
          v-model.number="costForm.form.current_certified"
          name="current_certified"
          type="number"
          min="1"
          class="w-20 h-6"
          @blur="stopTypingCertified"
          @keyup.enter="stopTypingCertified"
          @keyup.esc="typingCertified = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-green-200/50"
    >
      {{ tradeItemCost?.current_certified ? australianCurrency(tradeItemCost.current_certified) : '' }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(tradeItem.previous(claim)) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(tradeItem.previous(claim) + (tradeItemCost?.current_certified || 0)) }}
    </td>

    <td
      v-if="tradeItemCost?.current_certified && !typingCertified && canEditCertified"
      :class="{ 'cursor-pointer': canEditCertified }"
      class="bg-blue-300/50"
      @click="typeCertifiedPercent"
    >
      <div class="group flex items-center justify-end">
        <Icon
          name="trash"
          class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
          @click.stop.capture="deleteCost"
        />

        <Icon
          v-if="canEditCertified"
          name="edit"
          class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
        />
        {{ tradeItemCost?.current_certified ? `${australianNumber((tradeItemCost.current_certified / tradeItem.budget) * 100)}%` : '' }}
      </div>
    </td>

    <td
      v-else-if="canEditCertified"
      class="bg-blue-300/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="certifiedPercentInput"
          v-model.number="current_certified_percent"
          name="current_certified_percent"
          type="number"
          class="w-20 h-6"
          @blur="stopTypingCertified"
          @keyup.enter="stopTypingCertified"
          @keyup.esc="typingCertified = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-blue-300/50"
    >
      {{ tradeItemCost?.current_certified ? `${australianNumber((tradeItemCost.current_certified / tradeItem.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((tradeItem.previous(claim) / tradeItem.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ australianNumber(((tradeItem.previous(claim) + (tradeItemCost?.current_certified || 0)) / tradeItem.budget) * 100) }}%
    </td>

    <td class="pr-3">
      {{ australianCurrency(Math.abs(tradeItem.budget - tradeItem.previous(claim) - (tradeItemCost?.current_certified || 0))) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber, falsey, roundDecimals } from '@/helpers';
import Claim, { ClaimStatus } from '@/models/Claim';
import Trade from '@/models/Trade';
import TradeItem from '@/models/TradeItem';
import { useTradeItemCostsStore } from '@/store/tradeItemCosts';
import Swal from 'sweetalert2';
import { nextTick, ref, watch } from 'vue';
import { computed } from 'vue';

const props = defineProps<{
  trade: Trade;
  claim: Claim;
  tradeItem: TradeItem;
}>();

const tradeItemCostsStore = useTradeItemCostsStore();

const tradeItemCost = computed(() => {
  return tradeItemCostsStore.models.with('comments')
    .where('trade_item_id', props.tradeItem.id)
    .where('claim_id', props.claim.id)
    .first();
});

const canEditCertified = computed(() => {
  return props.claim.status === ClaimStatus.draft;
});

const typingCertified = ref(false);
const certifiedInput = ref();
const certifiedPercentInput = ref();

const typeCertified = () => {
  if(canEditCertified.value) {
    typingCertified.value = true;

    nextTick(() => {
      certifiedInput.value?.focusInput();
    });
  }
};

const typeCertifiedPercent = () => {
  if(canEditCertified.value) {
    typingCertified.value = true;

    nextTick(() => {
      certifiedPercentInput.value?.focusInput();
    });
  }
};

const costForm = useFormData({
  current_certified: undefined,
});

const current_certified_percent = computed({
  get() {
    return roundDecimals((costForm.form.current_certified / props.tradeItem.budget) * 100);
  },

  set(value) {
    costForm.form.current_certified = roundDecimals((value / 100) * props.tradeItem.budget);
  },
});

watch(tradeItemCost, (newValue) => {
  if(newValue) {
    costForm.setData(newValue);
  }
}, { immediate: true });

const storeTradeItemCostAction = useStoreApiAction(tradeItemCostsStore.createTradeItemCost);
const updateTradeItemCostAction = useStoreApiAction(tradeItemCostsStore.updateTradeItemCost);
const deleteTradeItemCostAction = useStoreApiAction(tradeItemCostsStore.deleteTradeItemCost);

const stopTypingCertified = async () => {
  if(costForm.form.current_certified === 0 || (falsey(costForm.form.current_certified) && !tradeItemCost.value)) {
    return;
  }

  if(falsey(costForm.form.current_certified) && tradeItemCost.value) {
    deleteTradeItemCostAction.request(tradeItemCost.value.id).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });
    });
  } else if(tradeItemCost.value) {
    updateTradeItemCostAction.request(tradeItemCost.value.id, costForm.form).then((tradeItemCost) => {
      console.log(tradeItemCost);
    }).catch((error) => {
      if(error.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid amount.',
          text: error.data.message,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong.',
        });
      }

      costForm.form.current_certified = tradeItemCost.value.current_certified;
    });
  } else {
    storeTradeItemCostAction.request(props.claim.id, props.tradeItem.id, costForm.form).then((tradeItemCost) => {
      console.log(tradeItemCost);
    }).catch((error) => {
      console.log(error);

      if(error.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid amount.',
          text: error.data.message,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong.',
        });
      }
    });
  }

  typingCertified.value = false;
};

const deleteCost = () => {
  deleteTradeItemCostAction.request(tradeItemCost.value.id).then(() => {
    costForm.form.current_certified = undefined;
  }).catch((error) => {
    Swal.fire({
      icon: 'error',
      title: 'Something went wrong.',
    });
  });
};
</script>

<style scoped></style>
