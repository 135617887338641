<template>
  <tr class="even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100 p-2 m-2">
    <td
      class="py-2 text-left pl-3"
    >
      {{ $props.variationOrder.code }}
    </td>

    <td class="pr-3">
      {{ australianCurrency($props.variationOrder.budget) }}
    </td>

    <td
      v-if="variationOrderCost?.current_certified && !typingCertified && canEditCertified"
      :class="{ 'cursor-pointer': canEditCertified }"
      class="bg-green-200/50"
      @click="typeCertified"
    >
      <div class="group flex items-center justify-end">
        <Icon
          name="trash"
          class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
          @click.stop.capture="deleteCost"
        />

        <Icon
          v-if="canEditCertified"
          name="edit"
          class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
        />
        {{ variationOrderCost.current_certified ? australianCurrency(variationOrderCost.current_certified) : '' }}
      </div>
    </td>

    <td
      v-else-if="canEditCertified"
      class="bg-green-200/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="certifiedInput"
          v-model.number="costForm.form.current_certified"
          name="current_certified"
          type="number"
          min="1"
          class="w-20 h-6"
          @blur="stopTypingCertified"
          @keyup.enter="stopTypingCertified"
          @keyup.esc="typingCertified = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-green-200/50"
    >
      {{ variationOrderCost?.current_certified ? australianCurrency(variationOrderCost.current_certified) : '' }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(variationOrder.previous(claim)) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(variationOrder.previous(claim) + (variationOrderCost?.current_certified || 0)) }}
    </td>

    <td
      v-if="variationOrderCost?.current_certified && !typingCertified && canEditCertified"
      :class="{ 'cursor-pointer': canEditCertified }"
      class="bg-blue-300/50"
      @click="typeCertifiedPercent"
    >
      <div class="group flex items-center justify-end">
        <Icon
          name="trash"
          class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
          @click.stop.capture="deleteCost"
        />

        <Icon
          v-if="canEditCertified"
          name="edit"
          class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
        />
        {{ variationOrderCost?.current_certified ? `${australianNumber((variationOrderCost.current_certified / variationOrder.budget) * 100)}%` : '' }}
      </div>
    </td>

    <td
      v-else-if="canEditCertified"
      class="bg-blue-300/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="certifiedPercentInput"
          v-model.number="current_certified_percent"
          name="current_certified_percent"
          type="number"
          class="w-20 h-6"
          @blur="stopTypingCertified"
          @keyup.enter="stopTypingCertified"
          @keyup.esc="typingCertified = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-blue-300/50"
    >
      {{ variationOrderCost?.current_certified ? `${australianNumber((variationOrderCost.current_certified / variationOrder.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((variationOrder.previous(claim) / variationOrder.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ australianNumber(((variationOrder.previous(claim) + (variationOrderCost?.current_certified || 0)) / variationOrder.budget) * 100) }}%
    </td>

    <td class="pr-3">
      {{ australianCurrency(variationOrder.budget - variationOrder.previous(claim) - (variationOrderCost?.current_certified || 0)) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber, falsey, roundDecimals } from '@/helpers';
import Claim, { ClaimStatus } from '@/models/Claim';
import VariationOrder from '@/models/VariationOrder';
import { useVariationOrderCostsStore } from '@/store/variationOrderCosts';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';

const props = defineProps<{
  variationOrder: VariationOrder;
  claim: Claim;
}>();

const variationOrderCostsStore = useVariationOrderCostsStore();

const variationOrderCost = computed(() => {
  return variationOrderCostsStore.models.where('claim_id', props.claim.id).where(
    'variation_order_id',
    props.variationOrder.id,
  ).first();
});

const canEditCertified = computed(() => {
  return props.claim.status === ClaimStatus.draft;
});

const typingCertified = ref(false);
const certifiedInput = ref();
const certifiedPercentInput = ref();

const typeCertified = () => {
  if(canEditCertified.value) {
    typingCertified.value = true;

    nextTick(() => {
      certifiedInput.value?.focusInput();
    });
  }
};

const typeCertifiedPercent = () => {
  if(canEditCertified.value) {
    typingCertified.value = true;

    nextTick(() => {
      certifiedPercentInput.value?.focusInput();
    });
  }
};

const current_certified_percent = computed({
  get() {
    return roundDecimals((costForm.form.current_certified / props.variationOrder.budget) * 100);
  },

  set(value) {
    costForm.form.current_certified = roundDecimals((value / 100) * props.variationOrder.budget);
  },
});

const costForm = useFormData({
  current_certified: undefined,
});

watch(variationOrderCost, (newValue) => {
  if(newValue) {
    costForm.setData(newValue);
  }
}, { immediate: true });

const storeVariationOrderCostAction = useStoreApiAction(variationOrderCostsStore.createVariationOrderCost);
const updateVariationOrderCostAction = useStoreApiAction(variationOrderCostsStore.updateVariationOrderCost);
const deleteVariationOrderCostAction = useStoreApiAction(variationOrderCostsStore.deleteVariationOrderCost);

const stopTypingCertified = async () => {
  if(costForm.form.current_certified === 0 || (falsey(costForm.form.current_certified) && !variationOrderCost.value)) {
    return;
  }

  if(falsey(costForm.form.current_certified) && variationOrderCost.value) {
    deleteVariationOrderCostAction.request(variationOrderCost.value.id).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });
    });
  } else if(variationOrderCost.value) {
    updateVariationOrderCostAction.request(variationOrderCost.value.id, costForm.form).then((variationOrderCost) => {
      console.log(variationOrderCost);
    }).catch((error) => {
      console.log(error);

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });

      costForm.form.current_certified = variationOrderCost.value.current_certified;
    });
  } else {
    storeVariationOrderCostAction.request(props.claim.id, props.variationOrder.id, costForm.form).then(
      (tradeItemCost) => {
        console.log(tradeItemCost);
      },
    ).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });
    });
  }

  typingCertified.value = false;
};

const deleteCost = () => {
  deleteVariationOrderCostAction.request(variationOrderCost.value.id).then(() => {
    costForm.form.current_certified = undefined;
  }).catch((error) => {
    Swal.fire({
      icon: 'error',
      title: 'Something went wrong.',
    });
  });
};
</script>

<style scoped></style>
