<template>
  <div class="flex items-center justify-center h-full space-y-2.5 my-8">
    <div
      class="flex flex-col border border-gray-300 p-5 justify-between
    items-center h-full space-y-6 element-island container"
    >
      <h1 class="text-4xl">
        Production Rate Dashboard
      </h1>

      <template v-if="projectsStore.project?.codes.length">
        <div class="flex justify-center w-full px-6">
          <div class="flex flex-col space-y-4 mx-auto w-1/4">
            <label>
              WBS Code

              <AppSelect
                v-model.number="forecastForm.form.wbs_code_id"
                :options="projectsStore.project?.codes.map((code) => ({ value: code.id, label: code.code }))"
                name="wbs_code"
                data-test="wbs_code"
                class="form-input"
              />
            </label>

            <label>
              WBS Description

              <AppSelect
                v-model.number="forecastForm.form.wbs_code_id"
                :options="projectsStore.project?.codes.map((code) => ({ value: code.id, label: code.description }))"
                name="wbs_code_description"
                data-test="wbs_code_description"
                class="form-input"
              />
            </label>

            <div class="flex space-x-4">
              <label class="w-1/2">
                Start

                <AppInput
                  v-model="forecastForm.form.start"
                  class="form-input"
                  type="date"
                  name="start"
                  :error="forecastForm.getErrors('start')"
                />
              </label>

              <label class="w-1/2">
                End Date
                <AppInput
                  v-model="forecastForm.form.end"
                  class="form-input"
                  type="date"
                  name="end"
                  :error="forecastForm.getErrors('end')"
                />
              </label>
            </div>

            <button
              type="button"
              class="btn btn--primary-blue"
              :disabled="fetchForecastAction.state.value === States.LOADING"
              @click="fetchForecast"
            >
              Load Rates
            </button>
          </div>

          <div
            v-if="fetchForecastAction.isNot(States.LOADING)"
            class="flex flex-col justify-between mx-auto w-1/4"
          >
            <div class="space-y-2">
              <div class="flex justify-between space-x-4">
                <p>
                  Tender Amount:
                </p>

                <p>
                  {{ australianCurrency(wbsCode?.tender_cost) }}
                </p>
              </div>

              <div class="flex justify-between space-x-4">
                <p>Forecast Cost: </p>

                <p>{{ australianCurrency(tender.cost_forecast) }}</p>
              </div>

              <div class="flex justify-between space-x-4">
                <p>Profit / Loss: </p>

                <p>{{ australianCurrency(tender.profit) }}</p>
              </div>
            </div>

            <hr>

            <div class="space-y-2">
              <div class="flex justify-between space-x-4">
                <p>
                  Cost to Date:
                </p>

                <p>
                  {{ australianCurrency(tender.cost_to_date) }}
                </p>
              </div>

              <div class="flex justify-between space-x-4">
                <p>Earned Amount: </p>

                <p>{{ australianCurrency(tender.earned_amount) }}</p>
              </div>

              <div class="flex justify-between space-x-4">
                <p>Cost to Complete: </p>

                <p>{{ australianCurrency(tender.cost_to_complete) }}</p>
              </div>
            </div>

            <hr>

            <div
              v-if="fetchForecastAction.isNot(States.LOADING) && wbsCode"
              class="flex space-x-4 justify-center"
            >
              <div class="border-2 rounded-md border-red-500 px-4 py-2 w-min text-center">
                <p class="w-max">
                  Tender Rate
                </p>

                <p>
                  {{ australianCurrency(wbsCode.unitCost) }}/{{ wbsCode.unit }}
                </p>
              </div>

              <div class="border-2 border-orange-400 px-4 py-2 w-min text-center rounded-md">
                <p class="w-max">
                  To Date
                </p>
                {{ australianCurrency(tender.to_date_unit_cost) }}/{{ wbsCode.unit }}
              </div>

              <div class="border-2 border-green-500 px-4 py-2 w-min text-center rounded-md">
                <p class="w-max">
                  To Complete
                </p>
                {{ australianCurrency(tender.to_complete_unit_cost) }}/{{ wbsCode.unit }}
              </div>
            </div>
          </div>


          <div
            v-else
            class="flex justify-center mx-auto w-1/4"
          >
            <AppSpinner class="w-40 flex justify-center items-center h-full" />
          </div>
        </div>

        <div class="h-96 w-full">
          <canvas
            ref="lineChart"
          />
        </div>
      </template>

      <div
        v-else
        class="text-center font-semibold w-full p-2"
      >
        No WBS Codes found.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, safeDenominator, timezoneOffset } from '@/helpers';
import { useDailyQuantitiesStore } from '@/store/dailyQuantities';
import { useProjectsStore } from '@/store/projects';
import { useWbsCodesStore } from '@/store/wbsCodes';
import { Chart } from 'chart.js/auto';
import { computed, onMounted, reactive, ref, watch } from 'vue';

const project = computed(() => {
  return projectsStore.project;
});

const lineChart = ref();
const projectsStore = useProjectsStore();
const today = new Date();

const firstDay = new Date(
  new Date(
    today.getFullYear(),
    today.getMonth(),
    1,
  ).getTime() - (timezoneOffset * 60 * 1000),
);

const lastDay = new Date(
  new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0,
  ).getTime() - (timezoneOffset * 60 * 1000),
);

const forecastForm = useFormData({
  wbs_code_id: project.value?.codes[0]?.id || undefined,
  start: firstDay.toISOString().slice(0, 10),
  end: lastDay.toISOString().slice(0, 10),
});

watch(project, (newValue) => {
  if(newValue) {
    forecastForm.form.wbs_code_id = newValue?.codes[0]?.id;
  }
});

const wbsCodesStore = useWbsCodesStore();

const wbsCode = computed(() => {
  return wbsCodesStore.models.find(forecastForm.form.wbs_code_id);
});

const tender = reactive({
  earned_amount: 0,
  cost_to_date: 0,
  cost_to_complete: 0,
  cost_forecast: 0,
  profit: 0,
  to_complete_unit_cost: 0,
  to_date_unit_cost: 0,
});

const averageToDateRate = ref(0);

const chartData = ref({
  labels: [],
  datasets: [
    {
      label: 'Tender Rate',
      data: [],
      fill: false,
      borderColor: 'red',
      tension: 0.1,
    },
    {
      label: 'Daily Rate',
      data: [],
      fill: false,
      borderColor: 'blue',
      tension: 0.1,
    },
    {
      label: 'To Complete',
      data: [],
      fill: false,
      borderColor: 'green',
      tension: 0.1,
    },
    {
      label: 'To Date',
      data: [],
      fill: false,
      borderColor: 'orange',
      tension: 0.1,
    },
  ],
});

let chart: Chart | undefined;

onMounted(() => {
  const ctx = lineChart.value.getContext('2d');

  chart = new Chart(ctx, {
    type: 'line',
    data: chartData.value,
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },

        title: {
          display: false,
        },
      },

      maintainAspectRatio: false,

      scales: {
        y: {
          ticks: {},
        },
      },
    },
  });

  if(
    forecastForm.form.wbs_code_id &&
    forecastForm.form.end &&
    forecastForm.form.start
  ) {
    fetchForecastAction.request(project.value.id, forecastForm.form).then((forecast: forecast) => {
      updateChart(forecast);
    }).catch((error) => {
      forecastForm.setErrors(error.data);
    });
  }
});

const dailyQuantitiesStore = useDailyQuantitiesStore();
const fetchForecastAction = useStoreApiAction(dailyQuantitiesStore.forecast);

interface forecast {
  tender_cost: number;
  tender_quantity: number;
  cost_to_date: number;
  quantity_to_date: number;
  earned_amount: number;
  cost_to_complete: number;
  cost_forecast: number;
  profit: number;
  range_hours: [K: number];
  range_cost: [K: number];
  range_quantities: [K: number];
  tender_unit_cost: number;
  to_date_unit_cost: number;
  to_complete_unit_cost: number;
}

const updateChart = (forecast: forecast) => {
  const start = forecastForm.form.start ?
    new Date(forecastForm.form.start) :
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);

  const end = forecastForm.form.end ? new Date(forecastForm.form.end) : new Date();
  const diffTime = Math.abs(end.getTime() - start.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
  const tenderRates = Array.from({ length: diffDays }, () => forecast.tender_unit_cost);
  const costToComplete = Array.from({ length: diffDays }, () => forecast.to_complete_unit_cost);

  const labels = Array.from({ length: diffDays }, (_, i) => {
    const date = new Date(start.getTime() + (i * 24 * 60 * 60 * 1000));

    return date.toISOString().slice(0, 10);
  });

  const rates = [];

  labels.forEach((label) => {
    if(
      label in forecast.range_quantities &&
      label in forecast.range_cost
    ) {
      rates.push(forecast.range_cost[label] / forecast.range_quantities[label]);
    } else {
      rates.push(0);
    }
  });

  tender.cost_forecast = forecast.cost_forecast;
  tender.cost_to_complete = forecast.cost_to_complete;
  tender.cost_to_date = forecast.cost_to_date;
  tender.earned_amount = forecast.earned_amount;
  tender.profit = forecast.profit;
  tender.to_complete_unit_cost = forecast.to_complete_unit_cost;
  tender.to_date_unit_cost = forecast.to_date_unit_cost;

  chart.data.labels = labels;

  averageToDateRate.value = forecast.cost_to_date / safeDenominator(forecast.quantity_to_date);

  const averagesToDate = Array.from({ length: diffDays }, () => averageToDateRate.value);

  chart.data.datasets[0].data = tenderRates;
  chart.data.datasets[1].data = rates;
  chart.data.datasets[2].data = costToComplete;
  chart.data.datasets[3].data = averagesToDate;

  chart.update();
};

const fetchForecast = () => {
  forecastForm.resetErrors();

  fetchForecastAction.request(project.value.id, forecastForm.form).then((forecast: forecast) => {
    updateChart(forecast);
  }).catch((error) => {
    forecastForm.setErrors(error.data);
  });
};

watch(forecastForm.form, (newValue) => {
  if(
    newValue.wbs_code_id &&
    newValue.end &&
    newValue.start &&
    chart
  ) {
    fetchForecast();
  }
});
</script>

<style scoped></style>
